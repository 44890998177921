export interface State { 
    user: User;
}
export const initialState: State = { 
    user: newUser(),
}
export interface User { 
    uid?: string; 
    lastSet?: string; 
    first_name?: string; 
    last_name?: string; 
    team_id?: string;
    age?: string;
    is_trainer?: boolean;
    exerciseList?: string;
}
export interface Team { 
    id?: string;
    team_name?: string;
    code?: string;
    members?: User[]
}
export interface ExcelObject {
    set_analytics?: Set_Analytics[];
    user?: User;
    event?: Event;
    team_name?: string;
}
export function newTeam() { 
    return {
        id: "",
        team_name: "",
        code: "",
        members: [] 
    }
    
}
export interface Event {
    allDay?: boolean;
    endTime?: Date;
    startTime?: Date;
    title?: string; //session name
    id?: string;
    notes?: string;
    date?: Date;
    assigned_by?: string;
    user_id?: string;
    routine_name?: string;
    routine_id?: string;
    is_completed?: boolean;
    is_team?: boolean;
}
export function setEvent(e) : Event {
    return {
        allDay : e.allDay ? e.allDay : null , 
        endTime : e.endTime ? e.endTime : null , 
        startTime : e.startTime ? e.startTime : null , 
        title : e.title ? e.title : null , 
        id : e.id ? e.id : null , 
        notes : e.notes ? e.notes : null , 
        date : e.date ? e.date : null , 
        assigned_by : e.assigned_by ? e.assigned_by : null , 
        user_id : e.user_id ? e.user_id : null , 
        routine_name : e.routine_name ? e.routine_name : null , 
        routine_id : e.routine_id ? e.routine_id : null , 
        is_completed : e.is_complete ? e.is_complete : null ,
     }
}
export interface Routine {
    id?: string;
    name?: string;
    sets_id?: string[];
    user_id?: string;
    is_template?: boolean;
}
export interface Set {
    id?: string;
    routine_id?: string;
    user_id?: string;
    exercise_name?: string;
    reps?: number;
    weight?: number;
    mean_target_velocity?: number;
    velocity_loss_target?: number;
    mean_power_target? : number;
    peak_power_target?: number;
    tag?: string;
    is_Percent?: boolean;
    custom?: boolean;
    is_benchmark?: boolean;
    unrecorded?: boolean;
    primary_muscle?: string[];
    secondary_muscle?: string[]; 
    unit?: string;
    weight_unit?: string;
    rest_time?: number;
    value?: string;
    RM?: number;
}
export interface Session {
    id?: string;
    routine_id?: string;
    user_id?: string;
    completed?: boolean;
}

export function setExercise(e) : Set {
    return {
        exercise_name: e.name? e.name: null, 
        unrecorded: e.unrecorded? e.unrecorded: false, 
        is_benchmark: e.is_benchmark? e.is_benchmark: false, 
        primary_muscle: e.primary_muscle? e.primary_muscle: null, 
        secondary_muscle: e.secondary_muscle? e.secondary_muscle: null, 
        unit: e.unit? e.unit: null, 
        weight: e.weight? e.weight: null, 
        weight_unit: e.weight_unit? e.weight_unit: null, 
        reps: e.reps? e.reps: null, 
        rest_time: e.rest_time? e.rest_time: null,
        RM: e.RM? e.RM:null,
        is_Percent: false,
        custom: e.custom? e.custom:false
    }
}

export function setRoutine(r) : Routine {
    return {
        id: r.id?r.id:null,
        name: r.name?r.name:null, 
        user_id: r.user_id?r.user_id:null,
    }
}
export function setSession(s) : Session {
    return {
        id: s.id?s.id:null,
        routine_id: s.routine_id?s.routine_id:null, 
        user_id: s.user_id?s.user_id:null,
        completed: s.user_id?s.user_id:null,
    }
}
export interface Set_Analytics {
    id?: string;
    set_id?: string;
    event_id?: string;
    user_id?: string;
    exercise_name?: string;
    reps?: number;
    weight?: number;
    tag?: string;
    set_raw?: Measurement[];
    mean_target_velocity?: number;
    mean_velocity?: number;
    peak_velocity?: number;
    velocity_loss?: number;
    mean_acceleration?: number;
    peak_acceleration?: number;
    mean_power?: number;
    peak_power?: number;
    date?: string;
    timestamp?: number;
    unrecorded?: boolean;
    value?: string;
    unit?: string;
    is_benchmark?: boolean;
}
export interface Measurement {
    timestamp: number;
    quaternion?: {
        w: number;
        x: number;
        y: number;
        z: number;
    }
    freeAcceleration?: {
        x: number;
        y: number;
        z: number;
    }
}

export interface Compressed_Set_Analytics {
    id: string;
    compressed: any;
    date: string;
    user_id: string;
}

export function newUser() : User {
    return {
        lastSet: "", 
        uid: "", 
        first_name: "", 
        last_name: "", 
        team_id: "",
        is_trainer: true
    }
}
