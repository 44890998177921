import { createAction, props } from '@ngrx/store';
import { State, initialState, User, Team, newUser} from 'src/app/store/models/state.model';
import firebase from 'firebase/app';

export const loadUser2 = createAction(
    '[USER] Load user2'
)

export const userLoadedSuccess = createAction(
    '[USER] User Loaded Success',
    props<{ user: any }>()
)

export const loadUser = createAction(
    '[USER] Load user',
    props<{ user: User }>()
)

// export const 

// export const loadWorkoutLogs = createAction(
//     '[WORKOUT_LOGS] Load Workout Logs',
//     props<{ workoutLogs: WorkoutLog[] }>()
// )

// export const loadWorkoutLogIds = createAction(
//     '[WORKOUT_LOGS] Load Workout Id',
//     props<{ workoutLogIds: string[] }>()
// )