import { Action, createReducer, on } from '@ngrx/store';
import * as StateActionTypes from './../actions/state.action';
import { initialState, State, User, Team, newUser, } from 'src/app/store/models/state.model';

const stateReducer = createReducer(
    initialState,
    on(StateActionTypes.loadUser, (state, {user}) => ({...state, user: user}))
    // ,on(StateActionTypes.loadWorkoutLogs, (state, {workoutLogs}) => ({...state, workoutLogs: workoutLogs}))
    // ,on(StateActionTypes.loadWorkoutLogIds, (state, {workoutLogIds}) => ({...state, workoutLogIds: workoutLogIds}))
    // ,on(StateActionTypes.loadUser2, (state) => ({... state}))

)

export function reducer(state: State | undefined, action: Action) {
    return stateReducer(state, action);
}
