import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { StoreModule } from '@ngrx/store';
import * as reducer from 'src/app/store/reducers/state.reducer';
import { EffectsModule } from '@ngrx/effects';
import { StateEffects } from './store/effects/state.effects';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { IonicStorageModule } from '@ionic/storage-angular';




@NgModule({
  declarations: [
    AppComponent,
  ],
  entryComponents: [],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    StoreModule.forRoot({ state: reducer.reducer }),
    EffectsModule.forRoot([ StateEffects ]),
    BrowserAnimationsModule,
    FormsModule,
    IonicStorageModule.forRoot(),
    ],
  providers: [

    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
