// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    apiKey: "AIzaSyCxewLX8ubwJprzdcUkaEN6Pcs33uZGvSQ",
    authDomain: "liftsmart-c1c3c.firebaseapp.com",
    databaseURL: "https://liftsmart-c1c3c.firebaseio.com",
    projectId: "liftsmart-c1c3c",
    storageBucket: "liftsmart-c1c3c.appspot.com",
    messagingSenderId: "305973170493",
    appId: "1:305973170493:web:50957c73c2b3366133f554",
    measurementId: "G-YTECQ5KF5K"
  },
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
