
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection  } from '@angular/fire/firestore';
import {  State, User, Set, Set_Analytics, Session, Routine, Event, Compressed_Set_Analytics } from 'src/app/store/models/state.model';
import { Observable } from 'rxjs';
import {v4 as uuidv4} from 'uuid';

@Injectable({
    providedIn: 'root'
})
export class StateService {
  
    user$: Observable<User>;
    user: User;

    load_flag: boolean;
    auth ;
    authentication;

    constructor (
        private afAuth: AngularFireAuth,
        private firestore: AngularFirestore
    ){
        this.setAuthState();
    }

    getAllEvents(){
        return this.firestore.collection('Events').valueChanges();
    }

    setAuthState() {
        this.afAuth.credential.forEach((a) => {
            if(a){
                console.log("CREDENTIAL");
                this.auth = a;
                console.log(a);
                this.getUser().forEach(u => {
                    this.user = u;
                });
            }
        });
        this.afAuth.authState.forEach((a) => {
            if(a){
                console.log("AUTHSTATE");
                this.auth = a;
                console.log(a);
                this.getUser().forEach(u => {
                    this.user = u;
                });
            }
        });
    }

    getAuthState() {
        return this.afAuth.authState;
    }
    

    getUser() : Observable<any> {
        if(!this.auth.uid){
            setTimeout(()=>{},250);
        }
        return this.firestore.doc<User>('/Users/' + this.auth.uid).valueChanges();
    }
    getAthlete(id) : Observable<any> {
        return this.firestore.doc<User>('/Users/' + id).valueChanges();
    }
    updateUser(user) {
        let userDoc = this.firestore.doc<Routine>('Users/'+user.uid);
        userDoc.set(user);
    }
    updateEvent(event) {

        var e: any = {
            ... event,
            startTime: event.startTime.toString(),
            endTime: event.endTime.toString(),
            date: saveDate(event.date)
        }
        console.log(e);
        let eventDoc = this.firestore.doc<Event>('Events/'+e.id);
        eventDoc.set(e);
    }
    updateEventTitle(e) {
        let eventDoc = this.firestore.doc<Event>('Events/'+e.id);
        eventDoc.set(e);
    }

    // Returns a User's Sessions which satisfying given boolean completed
    getIncompleteSessions(completed: Boolean) : Observable<any> {
        return this.firestore.collection('Sessions', ref => ref.where("user_id", "==", this.auth.uid).where("is_completed", "==", completed)).valueChanges();
    }
    // Returns a User's Events which satisfying given boolean completed
    getIncompleteEvent(completed: Boolean, user_id?: string) : Observable<any> {
        return this.firestore.collection('Events', ref => ref.where("user_id", "==", user_id?user_id:this.auth.uid).where("is_completed", "==", completed)).valueChanges();
    }

    getTodayEvents() : Observable<any> {
        return this.firestore.collection('Events', ref => ref.where("date", "==", saveDate(new Date())).where("user_id", "==", this.auth.uid).where("is_completed", "==", false)).valueChanges();
    }
    getPreviousEvents(user_id?) : Observable<any> {
        //ToDO: fix date range so we only get today's event
        return this.firestore.collection('Events', ref => ref.where("date", "<=", saveDate(new Date())).where("user_id", "==", user_id?user_id:this.auth.uid).orderBy("date", "desc").limit(5)).valueChanges();
    }
    getUpcomingEvents() : Observable<any> {
        //ToDO: fix date range so we only get today's event
        return this.firestore.collection('Events', ref => ref.where("date", ">", saveDate(new Date())).where("user_id", "==", this.auth.uid).orderBy("date").limit(5)).valueChanges();
    }

    // Returns a User's Routine from a given Routine id
    getRoutine(routine_id) : Observable<any> {
        return this.firestore.collection('Routines', ref => ref.where("user_id", "==", this.auth.uid).where("id", "==", routine_id)).valueChanges();
    }
    getRoutineFromId(routine_id) : Observable<any>{
        return this.firestore.collection('Routines', ref => ref.where("id", "==", routine_id)).valueChanges();
    }
    getAllRoutine() : Observable<any> {
        return this.firestore.collection('Routines', ref => ref.where("user_id", "==", this.auth.uid)).valueChanges();
    }
    getAllRoutineTemplate() : Observable<any> {
        return this.firestore.collection('Routines', ref => ref.where("user_id", "==", this.auth.uid).where("is_template", "==", true)).valueChanges();
    }
    // Returns Set from a given Routine
    getSets(routine_id) : Observable<any> {
        return this.firestore.collection('Sets', ref => ref.where("routine_id", "==", routine_id)).valueChanges();
    }
    getSet(set_id){
        return this.firestore.collection('Sets', ref => ref.where("id", "==", set_id)).valueChanges();
    }

    // TODO: Get 50 most recent!
    getSet_Analytics() : Observable<any> {
        return this.firestore.collection('Set_Analytics', ref => ref.where("user_id", "==", this.auth.uid)).valueChanges();
    }
    get_all_set_analytics() : Observable<any> {
        return this.firestore.collection('Set_Analytics').valueChanges();
    }

    get_compressed_set_analytics(user_id?: string) : Observable<any> {
        return this.firestore.collection('Compressed_Set_Analytics', ref => ref.where("user_id", "==", user_id?user_id:this.auth.uid)).valueChanges();
    } 
    set_compressed_set_analytics(csa : Compressed_Set_Analytics) {
        csa.id = uuidv4();
        let csaDoc = this.firestore.doc<Routine>('Compressed_Set_Analytics/'+csa.id);
        csaDoc.set(csa);
        return csa;
    }
    update_compressed_set_analytics(csa : Compressed_Set_Analytics) {
        let csaDoc = this.firestore.doc<Routine>('Compressed_Set_Analytics/'+csa.id);
        csaDoc.set(csa);
    }
    // Returns Set_Analytics from a given Session
    getEventSet_Analytics(event_id) : Observable<any> {
        return this.firestore.collection('Set_Analytics', ref => ref.where("event_id", "==", event_id)).valueChanges();
    }

    get_exercise_set_analytics(exercise_name : string, user_id?: string) : Observable<any> {
        return this.firestore.collection('Set_Analytics', ref => ref.where("user_id", "==", user_id?user_id:this.auth.uid).where("exercise_name", "==", exercise_name)).valueChanges();
    }

    get_benchmark_analytics(exercise_name : string, user_id?: string) : Observable<any> {
        return this.firestore.collection('Set_Analytics', ref => ref.where("user_id", "==", user_id?user_id:this.auth.uid).where("exercise_name", "==", exercise_name).where("is_benchmark", "==", true)).valueChanges();
    }

    get_benchmark_analytics_test() : Observable<any> {
        return this.firestore.collection('Set_Analytics', ref => ref.where("is_benchmark", "==", true)).valueChanges();
    }
    get_recent_set_analytics(user_id: string) : Observable<any> {
        return this.firestore.collection('Set_Analytics', ref => ref.where("user_id", "==", user_id?user_id:this.auth.uid).orderBy("timestamp").limit(1)).valueChanges();
    }

    // deleteSet_Analytics(id : string){
    //     let sa = this.firestore.doc<Set_Analytics>('Set_Analytics/'+id);
    //     this.itemDoc.update(item); 
    // }

    getEvents(user_id): Observable<any> {
        return this.firestore.collection('Events', ref => ref.where("user_id", "==", user_id)).valueChanges(); 
    }
    getEvent(e_id): Observable<any> {
        return this.firestore.doc('Events/'+e_id,).valueChanges(); 
    }

    getAssignedEvents(assigned_by) : Observable<any> { 
        return this.firestore.collection('Events', ref => ref.where("assigned_by", "==", assigned_by).where("user_id", "!=", assigned_by)).valueChanges(); 
    }

    // Create Routine Document 
    createRoutine(routine : Routine) {
        let routineDoc = this.firestore.doc<Routine>('Routines/'+routine.id);
        routineDoc.set(routine);
        return routine;
    }
    // Create New Routine Document 
    createNewRoutine(routine : Routine) {
        routine.id = uuidv4();
        routine.user_id = this.user.uid;
        let routineDoc = this.firestore.doc<Routine>('Routines/'+routine.id);
        routineDoc.set(routine);
        return routine;
    }
    updateRoutine(routine : Routine){
        let routineDoc = this.firestore.doc<Routine>('Routines/'+routine.id);
        routineDoc.set(routine);
    }

    // Create Session Document 
    createSession(session : Session) {
        session.id = uuidv4();
        let sessionDoc = this.firestore.doc<Session>('Sessions/'+session.id);
        sessionDoc.set(session);
        return session;
    }
    updateSession(session : Session){
        let sessionDoc = this.firestore.doc<Routine>('Sessions/'+session.id);
        sessionDoc.set(session);
    }

    // Create Set Document 
    createSet(set : Set) {
        let setDoc = this.firestore.doc<Set>('Sets/'+set.id);
        setDoc.set(set);
        return set;
    }

    updateSet(set : Set){
        let setDoc = this.firestore.doc<Routine>('Sets/'+set.id);
        setDoc.set(set);
    }

    createNewSet(set : Set) {
        set.id = uuidv4();
        let setDoc = this.firestore.doc<Set>('Sets/'+set.id);
        setDoc.set(set);
        return set;
    }

    // Create Set_Analytics Document 
    createSetAnalytics(sa : Set_Analytics){
        sa.id = uuidv4();
        console.log(sa);
        let setAnalyticsDoc = this.firestore.doc<Set_Analytics>('Set_Analytics/'+sa.id);
        setAnalyticsDoc.set(sa);
        return sa;
    }

    updateSetAnalytic(sa : Set_Analytics){
        let saDoc = this.firestore.doc<Routine>('Set_Analytics/'+sa.id);
        saDoc.set(sa);
    }

    createEvent(event : Event){
        var e: any = event
        e.startTime = event.startTime.toString();
        e.endTime = event.endTime.toString();
        e.date = saveDate(event.date);

        e.id = uuidv4();
        let eventDoc = this.firestore.doc<Event>('Events/'+e.id);
        eventDoc.set(e);

        return e;

    }
    createEventBlank(event: Event){
        var e: any = event
        e.user_id = this.user.uid;
        e.date = saveDate(new Date());
        e.id = uuidv4();
        let eventDoc = this.firestore.doc<Event>('Events/'+e.id);
        eventDoc.set(e);
        return e;
    }

    updateEventBlank(event: Event){
        var e: any = event
        e.date = e.date ? e.date : saveDate(new Date());
        let eventDoc = this.firestore.doc<Event>('Events/'+e.id);
        eventDoc.set(e);
        return e;
    }
    updateTeamEvent(event: Event, uid){
        event.user_id = uid
        var e: any = event;
        e.is_completed = true;
        e.is_template = false;
        console.log("EVENT")
        console.log(e);
        
        let eventDoc = this.firestore.doc<Event>('Events/'+e.id);
        eventDoc.update(e);
        return e;
    }
    updateTeamAnalytics(sa : Set_Analytics){
        sa.id = uuidv4();
        console.log(sa);
        let setAnalyticsDoc = this.firestore.doc<Set_Analytics>('Set_Analytics/'+sa.id);
        setAnalyticsDoc.set(sa);
        return sa;
    }

    getTeam(team_id : string) : Observable<any>{
        return this.firestore.doc('Teams/'+team_id).valueChanges();
    }

    getTeamAthletes(team_id : string) : Observable<any>{
        return this.firestore.collection('Users', ref => ref.where("team_id", "==", team_id)).valueChanges();
    }

    getTeamByCode(team_code : string) : Observable<any>{
        return this.firestore.collection('Teams', ref => ref.where("code", "==", team_code)).valueChanges();
    }

    getUuid(){
        return uuidv4();
    }

    deleteEvent(event){
        let eventDoc = this.firestore.doc<Event>('Events/'+event.id);
        eventDoc.delete();
    }

    deleteSet(set){
        let setDoc = this.firestore.doc<Set>('Sets/'+set.id);
        setDoc.delete();
    }

    deleteSetAnalytic(sa){
        let saDoc = this.firestore.doc<Set>('Set_Analytics/'+sa.id);
        saDoc.delete();
    }
    deleteRoutine(routine){
        let eventDoc = this.firestore.doc<Routine>('Routines/'+routine.id);
        eventDoc.delete();
    }


}

export function saveDate(date: Date){
    const currentYear = date.getFullYear();
    const currentMonth = ("0" + (date.getMonth() + 1)).slice(-2);
    const currentDay = ("0" + (date.getDate())).slice(-2);

    return (currentYear + '-' + currentMonth + '-' + currentDay);

}