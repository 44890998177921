import { Injectable } from '@angular/core';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { StateService } from './state.service'; 
import { EMPTY } from 'rxjs';

@Injectable()
export class StateEffects {

  // loadUser$ = createEffect(() => this.actions$.pipe(
  //   ofType('[USER] Load user2'),
  //   mergeMap(() => this.stateService.getUser()
  //     .pipe(
  //       map(user => ({ type: '[USER] User Loaded Success', payload: user })),
  //       catchError(() => EMPTY)
  //     ))
  //   )
  // );

  constructor(
    private actions$: Actions,
    private stateService: StateService,
  ) {}
}