import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { SplashScreen } from '@capacitor/splash-screen';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'
  ]
})
export class AppComponent {

    appPages = [

      {
        title: 'Dashboard',
        url: '/home',
        ionicIcon: 'home'
      },
      {
        title: 'Record',
        url: '/record',
        ionicIcon: 'barbell'
      },
      {
        title: 'Analytics',
        url: '/analytics',
        ionicIcon: 'bar-chart'
      },
      {
        title: 'Builder',
        url: '/builder',
        ionicIcon: 'construct'
      },
      {
        title: 'Calendar',
        url: '/calendar',
        ionicIcon: 'calendar'
      },
      {
        title: 'Team',
        url: '/team',
        ionicIcon: 'people'
      },
    ];
    accountPages = [
      {
        title: 'Settings',
        url: '/setting',
        ionicIcon: 'cog'
      },

    ];

    textDir = 'ltr';


  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
  ) 
  {
    this.initializeApp();
  }

  async initializeApp() {
    try {
      await SplashScreen.hide();

    } catch (err) {
      console.log('Normal Browser', err);
    }
  }
  signOut(): void {
    console.log("Sign out");
    this.afAuth.signOut().then(()=> {this.router.navigate(['/login'])});
  }

}
